<template>
  <div>
    <div v-if="isloading">
      <div id="loading-spinner-div" class="text-center">
        <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
      </div>
    </div>
    <div v-else>
      <b-tabs title="Berechnung" content-class="mt-3">
        <b-tab title="Rationen" active>
          <template #title>
            <b-icon icon="calculator"></b-icon>
          </template>
          
        <div>
          <b-tabs content-class="mt-3">
              <b-tab title="Rationen" active>
                  <b-container class="ration-input-rows">
                      <b-row>
                          <b-col><b-button v-b-modal.add-ration-modal>+</b-button></b-col>
                      </b-row>
                  </b-container>

                  <b-container class="ration-table-container">
                      <b-table :items="rations" :fields="['name', { key: 'count', label: 'Anzahl' }, { key: 'editBtn', label: '' }]">
                        <template #cell(editBtn)="row">
                          <b-button size="sm" class="mr-2" @click="row.toggleDetails">
                            <b-icon icon="pencil"></b-icon>
                          </b-button>
                        </template>
                        <template #row-details="row">
                          <b-card>
                            <b-container>
                              <b-row>
                                <b-form-input id="add-ration-modal-input-ration" v-model="row.item.name" type="text" placeholder="Ration" label="Ration"></b-form-input>
                              </b-row>
                              <br />
                              <b-row>
                                <b-form-input v-model="row.item.count" type="number" placeholder="Anzahl" label="Anzahl" @change="calculate()"></b-form-input>
                              </b-row>
                              <br />
                              <b-row>
                                <b-col><b-button variant="danger" @click="removeRation(row)"><b-icon icon="trash"></b-icon></b-button></b-col>
                              </b-row>
                            </b-container>
                          </b-card>
                        </template>
                      </b-table>
                  </b-container>
              </b-tab>
              <b-tab title="Futtersorten">
                  <b-container class="feed-types-input-rows">
                      <b-row>
                          <b-col>
                            <b-button-group>
                              <b-button v-b-modal.add-feedtype-modal>+</b-button>
                            </b-button-group>
                          </b-col>
                      </b-row>
                  </b-container>

                  <b-container class="feed-types-table-container">
                      <b-table select-mode="range" :items="feedTypes" :fields="['name', { key: 'amount', label: 'KG' }, 'ts', { key: 'editBtn', label: ''}]" selectable @row-selected="onRowSelected">
                        <template #cell(name)="data">
                          {{ data.item.name }} ({{ data.item.ration.name }})
                        </template>
                        <template #cell(editBtn)="row">
                            <b-button-group>
                              <b-button size="sm" class="mr-2" @click="row.toggleDetails">
                                <b-icon icon="pencil"></b-icon>
                              </b-button>
                            </b-button-group>
                        </template>
                        <template #row-details="row">
                          <b-card>
                            <b-container>
                                <b-row><b-form-select v-model="row.item.rationName" :options="rations" value-field="name" text-field="name" @change="editFeedTypeRation(row)"></b-form-select></b-row>
                                <br />
                                <b-row><b-form-input v-model="row.item.name" type="text" placeholder="Futtersorte"></b-form-input></b-row>
                                <br />
                                <b-row><b-form-input v-model="row.item.amount" type="number" placeholder="KG" @change="calculate()"></b-form-input></b-row>
                                <br />
                                <b-row><b-form-input v-model="row.item.ts" type="number" placeholder="TS" @change="calculate()"></b-form-input></b-row>
                                <br />
                                <b-row>
                                  <b-col>
                                    <b-button-group>
                                      <b-button @click="moveFeedTypeUp(row)"><b-icon icon="arrow-up"></b-icon></b-button>
                                      <b-button @click="moveFeedTypeDown(row)"><b-icon icon="arrow-down"></b-icon></b-button>
                                      <b-button variant="danger" @click="removeFeedType(row)"><b-icon icon="trash"></b-icon></b-button>
                                    </b-button-group>
                                  </b-col>
                                </b-row>
                            </b-container>
                          </b-card>
                        </template>
                      </b-table>
                  </b-container>
              </b-tab>
              <b-tab title="Berechnung">
                  <b-container class="calculation-table-container">
                      <b-form-select v-model="calculationFilter" :options="rationsFilter" value-field="name" text-field="name" @change="filteredCalculationChanged"></b-form-select>
                      <br />
                      <br />
                      <b-table ref="calculationTable" v-model="filteredCalculation" :items="feedTypes" :fields="[{ key: 'name', label: 'Futtersorte'}, { key: 'calculation', label: 'Menge'}, { key: 'cumulativeCalculation', label: 'Summe'}]" :filter="calculationFilter">
                        <template #cell(name)="data">
                          {{ data.item.name }} ({{ data.item.ration.name }})
                        </template>
                      </b-table>
                  </b-container>

                  <div>
                    <b-alert
                      :show="dismissCountDown"
                      dismissible
                      variant="success"
                      @dismissed="dismissCountDown=0"
                      @dismiss-count-down="countDownChanged"
                    >
                      <p>Speicher erfolgreich!</p>
                      <b-progress
                        variant="success"
                        :max="dismissSecs"
                        :value="dismissCountDown"
                        height="4px"
                      ></b-progress>
                    </b-alert>
                    <b-button><b-icon icon="save" @click="saveCalculation()"></b-icon></b-button>
                </div>
              </b-tab>
          </b-tabs>

          <b-modal id="add-ration-modal" title="Neue Ration hinzufügen" centered>
            <b-container>
              <b-row>
                <b-form-input id="add-ration-modal-input-ration" v-model="ration.name" type="text" placeholder="Ration" label="Ration"></b-form-input>
              </b-row>
              <br />
              <b-row>
                <b-form-input v-model="ration.count" type="number" placeholder="Anzahl" label="Anzahl">
              </b-form-input></b-row>
            </b-container>

            <template #modal-footer="{ cancel }">
              <b-button size="sm" variant="success" @click="addRation()">
                Hinzufügen
              </b-button>
              <b-button size="sm" variant="danger" @click="cancel()">
                Abbrechen
              </b-button>
            </template>
          </b-modal>

          <b-modal id="add-feedtype-modal" title="Neue Futtersorte hinzufügen" centered>
            <b-container>
                <b-row><b-form-select v-model="feedType.rationName" :options="rations" value-field="name" text-field="name"></b-form-select></b-row>
                <br />
                <b-row><b-form-input v-model="feedType.name" type="text" placeholder="Futtersorte"></b-form-input></b-row>
                <br />
                <b-row><b-form-input v-model="feedType.amount" type="number" placeholder="KG"></b-form-input></b-row>
                <br />
                <b-row><b-form-input v-model="feedType.ts" type="number" placeholder="TS"></b-form-input></b-row>
            </b-container>

            <template #modal-footer="{ cancel }">
              <b-button size="sm" variant="success" @click="addFeedType()">
                Hinzufügen
              </b-button>
              <b-button size="sm" variant="danger" @click="cancel()">
                Abbrechen
              </b-button>
            </template>
          </b-modal>
        </div>
        </b-tab>
          <b-tab title="History">
            <template #title>
              <b-icon icon="clock-history"></b-icon>
            </template>
            
            <div>
              <b-table :items="history" :fields="[{ key:'name', label: 'Datensatz' }, { key: 'btn', label: ''}]">
                <template #cell(btn)="row">
                  <b-button size="sm" class="mr-2" @click="row.toggleDetails">
                    <b-icon icon="display"></b-icon>
                  </b-button>
                </template>

                <template #row-details="row">
                  <b-card>
                    <b-table :items="row.item.feedTypes" :fields="[{ key: 'ration.name', label: 'Ration'}, 'name', { key: 'amount', label: 'Anzahl' }, 'ts']" stacked></b-table>
                    
                    <b-container>
                      <b-row>
                        <b-col><b-button size="sm" @click="loadRowFromHistory(row)" block><b-icon icon="cloud-download"></b-icon></b-button></b-col>
                        <b-col><b-button size="sm" @click="row.toggleDetails" block>-</b-button></b-col>
                      </b-row>
                    </b-container>
                  </b-card>
                </template>
              </b-table>
            </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
  
<script>
  import { getHistory, saveCalculation } from "../service/HistoryService";

  export default {
    props: {
      username: String
    },
    data() {
      return {
        isloading: true,
        ration: {
            name: "",
            count: "",
        },
        rations: [],
        rationsFilter: [{ name: ''}],

        feedType: {
            rationName: null,
            ration: null,
            name: "",
            amount: "",
            ts: "",
        },
        feedTypes: [],
        selectedFeedTypes: [],

        calculationFilter: '',
        calculation: [],
        filteredCalculation: [],

        dismissSecs: 5,
        dismissCountDown: 0,
        showDismissibleAlert: false,

        history: []
      }
    },
    methods: {
      changeTab(index) {
        this.activeTab = index;
      },
      addRation() {
        this.rations.push({
          name: this.ration.name,
          count: this.ration.count
        });

        this.rationsFilter.push(this.ration.name);

        this.ration.name = "";
        this.ration.count = null;

        this.$bvModal.hide('add-ration-modal');
      },
      removeRation(row) {
        this.feedTypes = this.feedTypes.filter(x => x.ration.name != row.item.name);
        this.rations = this.rations.filter(x => x.name != row.item.name);
        this.rationsFilter = this.rationsFilter.filter(x => x != row.item.name);
      },
      addFeedType() {
        this.feedTypes.push({
          rationName: this.feedType.rationName,
          ration: this.rations.find((x) => x.name == this.feedType.rationName),
          name: this.feedType.name,
          amount: this.feedType.amount,
          ts: this.feedType.ts,
          calculation: 0,
          cumulativeCalculation: 0
        })

        this.feedType.rationName = null;
        this.feedType.ration = null;
        this.feedType.name = "";
        this.feedType.amount = null;
        this.feedType.ts = null;
        this.feedType.calculation = 0;
        this.feedType.cumulativeCalculation = 0;

        this.$bvModal.hide('add-feedtype-modal');

        this.calculate();
      },
      editFeedTypeRation(row) {
        row.item.ration = this.rations.find((x) => x.name == row.item.rationName);
      },
      feedTypesTableOnRowSelected(items) {
        this.selectedFeedTypes = items
      },
      removeFeedType(row) {
        var filteredElement = this.feedTypes.find(x => x.name === row.item.name && x.ration.name === row.item.ration.name);
        const index = this.feedTypes.indexOf(filteredElement);
        if (index > -1) {
          this.feedTypes.splice(index, 1);
        }
      },
      calculate() {
        var cumulativeCalculation = 0;

        this.feedTypes.forEach((feedType) => {
          feedType.calculation = Number(Number((Number(feedType.amount) / Number(feedType.ts)) * 100 * Number(feedType.ration.count)).toFixed(1));
          cumulativeCalculation += feedType.calculation;
          feedType.cumulativeCalculation = Number(cumulativeCalculation.toFixed(1));
        });

        if (this.$refs.calculationTable)
          this.$refs.calculationTable.refresh();
      },
      filteredCalculationChanged(){
        
        console.log(this.filteredCalculation);

        var cumulativeCalculation = 0;
        this.filteredCalculation.forEach((feedType) => {
          cumulativeCalculation += feedType.calculation;
          feedType.cumulativeCalculation = Number(cumulativeCalculation.toFixed(1));
        });

        if (this.$refs.calculationTable)
          this.$refs.calculationTable.refresh();
      },
      loadRowFromHistory(row) {
        this.loadDataRow(row.item.feedTypes);
      },
      loadEntryFromHistory(entry) {
        this.loadDataRow(entry.feedTypes);
      },
      loadDataRow(historyFeedTypes) {
        this.rations = [];
        this.rationsFilter = [{ name: ''}];
        this.feedTypes = [];

        var all_rations = Array();

        historyFeedTypes.forEach(feedType => {
          all_rations.push({
            name: feedType.ration.name,
            count: feedType.ration.count
          })
        });

        var uniqueRationNames = [...new Set(all_rations.map((item) => item.name))];

        uniqueRationNames.forEach((uniqueRationName) => {
          this.rations.push(all_rations.find(x => x.name == uniqueRationName))
          this.rationsFilter.push(uniqueRationName)
        });

        historyFeedTypes.forEach(feedType => {
          this.feedTypes.push({
              rationName: feedType.ration.name,
              ration: this.rations.find(x => x.name == feedType.ration.name),
              name: feedType.name,
              amount: feedType.amount,
              ts: feedType.ts
          });
        });

        this.calculate();
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      },
      saveCalculation() {
        saveCalculation(this.feedTypes).then((response) => {
            var data = JSON.parse(response)
            if (data.success == true) {
              this.showAlert()
            }
        });
        
        this.loadHistoryData();
      },
      loadHistoryData() {
        getHistory().then((response) => {
          this.history = new Array();

          var data = JSON.parse(response)
          data.data.forEach((data) => {
            const splittedFileName = data.name.split(".");
            var date = splittedFileName[0];

            var year = date.slice(4,8);
            var month = date.slice(2,4);
            var day = date.slice(0,2);

            date = day + "." + month + "." + year;

            this.history.push({
              name: date,
              date: Date.parse(year + "-" + month + "-" + day),
              feedTypes: JSON.parse(data.feedTypes)
            })
          });
          
          this.history.sort((a,b) => b.date - a.date);

          if (this.history.length > 0) {
            this.loadEntryFromHistory(this.history[0]);
          }

          this.isloading = false;
        });
      },
      onRowSelected(items) {
        this.selectedFeedTypes = items;
      },
      moveFeedTypeUp(row) {
        var filteredElement = this.feedTypes.find(x => x.name === row.item.name && x.ration.name === row.item.ration.name);
        const index = this.feedTypes.indexOf(filteredElement);
        if (index > 0) {
          this.feedTypes = this.arrayMove(this.feedTypes, index, index-1);
        }
      },
      moveFeedTypeDown(row) {
        var filteredElement = this.feedTypes.find(x => x.name === row.item.name && x.ration.name === row.item.ration.name);
        const index = this.feedTypes.indexOf(filteredElement);
        if ((index+1) < this.feedTypes.length) {
          this.feedTypes = this.arrayMove(this.feedTypes, index, index+1);
        }
      },
      arrayMove(arr, oldIndex, newIndex) {
        while (oldIndex < 0) {
          oldIndex += arr.length;
        }
        while (newIndex < 0) {
          newIndex += arr.length;
        }
        if (newIndex >= arr.length) {
          let i = newIndex - arr.length + 1;
          while (i--) {
            arr.push(undefined);
          }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr;
      }
	},
  mounted() {
    this.loadHistoryData();
  }
};
</script>
  
<style scoped>
  /* Hier werden die Bootstrap-Klassen verwendet */
  .responsive-tabs {
    overflow-x: auto;
  }

  .ration-table-container {
    margin-top: 10px;
  }

  .feed-types-table-container {
    margin-top: 10px;
  }

  #loading-spinner-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  @media (max-width: 768px) {
    .responsive-tabs .nav-tabs {
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  }
</style>