export async function getHistory() {
    const response = await fetch(`https://node-api.julian-steiner.net//cowfeedcalculator/calculations`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
    });
    
    return await response.text();
}

export async function saveCalculation(feedTypes) {
    const response = await fetch(`https://node-api.julian-steiner.net//cowfeedcalculator/calculation/save`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ feedTypes: feedTypes }),
    });

    return await response.text();
}